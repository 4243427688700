<template>
  <div class="xform__repeater">
    <b-form
      ref="form"
      :style="{height: trHeight}"
      class="repeater-form">
      <div v-if="isView && (!dataList || dataList.length === 0)">
        <b-img center height="60" :src="require('@/@core/assets/images/empty.svg')"/>
      </div>
      <div v-for="(item,index) in dataList" :id="item.id" :key="item.id" ref="row" class="d-flex align-items-center pb-50">
        <slot name="form-repeater" :row="dataList" :item="dataList[index]" :index="index" :data="index">
        </slot>
        <slot v-if="!noActions" name="actions">
          <b-button
            v-if="!isView && ( isFirst === true || index !== 0 )"
            v-ripple.400="$x.ripple.danger" variant="flat-danger" size="sm" @click="removeItem(index)" class="btn-light-danger" pill>
            <!--<feather-icon icon="MinusIcon" class=" mr-50"/>-->
            <span class="align-middle">{{ deleteText ? deleteText : '删除'}}</span>
          </b-button>
        </slot>
      </div>
      <b-button
        v-if="!isView"
        v-ripple.400="$x.ripple.white" variant="flat-primary" size="sm" @click="addItem" class="mb-50 btn-light-primary" pill>
        <feather-icon icon="PlusIcon" class="ml-50 mr-50"/>
        <span class="align-middle mr-50">{{ addText ? addText : '新增'}}</span>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BButton, BImg,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    // 第一个是否可以删除 默认不可以
    isFirst: {
      type: Boolean,
      default: false,
    },
    addText: {
      type: String,
      default: undefined,
    },
    deleteText: {
      type: String,
      default: undefined,
    },
    data: {
      type: Array,
      default: () => [],
    },
    itemAdd: {
      type: Function,
      default: () => {},
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    noAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nextId: 2,
      dataList: this.data,
    }
  },
  watch: {
    data() {
      this.dataList = this.data
    },
  },
  methods: {
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    addItem() {
      this.dataList.push(this.itemAdd() || {})
      // this.$emit('update:data', this.data)
      if (this.noAnimation === true) {
        return
      }
      this.$nextTick(() => {
        if (this.$refs.row) {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        }
      })
    },
    removeItem(index) {
      this.dataList.splice(index, 1)
      if (this.noAnimation === true) {
        return
      }
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
  },
  mounted() {
    if (this.noAnimation === true) {
      return
    }
    this.initTrHeight()
  },
  created() {
    // this.data = [{ url: 11, protocol: 22 }, { url: 11, protocol: 22 }]
    if (this.noAnimation === true) {
      return
    }
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    if (this.noAnimation === true) {
      return
    }
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>

<style lang="scss">
  .xform__repeater{
    height: inherit;
    .repeater-form {
      /*overflow: hidden;*/
      transition: .35s height;
    }
  }
</style>
