const iot = {
  namespaced: true,
  state: {
    units: [],
    mapUnits: [],
  },
  actions: {
  },
  mutations: {
    SET_UNITS(state, units) {
      if (state.units && state.units.length > 0) {
        return
      }
      state.units = units || []
      let kvUnits = {}
      units.forEach(item => {
        kvUnits[item.id] = item
      })
      state.mapUnits = kvUnits
    },
  },

}
export default iot
