import Vue from 'vue'
import { getToken } from '@core/auth/token'
// import baseUrl from '@/config/env'
import { queryAllNotify as queryNotifyAlarms } from '@/api/system/usercenter/notification'

var ws
var count = 0
var timer
var dataHandlers = new Map()
var isInitial = false
var handleSubDeviceAlarm
/**
 * 消息类型
 * @type {{ALARM: string, PROPERTY_REPORT: string, ONLINE: string, OFFLINE: string, EVENT: string}}
 */
const TYPE = {
  REGISTER: 'register',
  UNREGISTER: 'unregister',
  ONLINE: 'online',
  OFFLINE: 'offline',
  EVENT: 'event',
  ALARM: 'alarm',
  PROPERTY_REPORT: 'propertyReport',
  FUNCTION: 'function',
  FUNCTION_REPLY: 'function-reply',
  AUTH_ERROR: 'authError',
}

// 发送订阅数据
const sendData = (id, topic, parameter) => {
  try {
    if (ws) {
      ws.send(JSON.stringify({ id, topic, parameter, type: 'sub' }))
      return true
    }
  } catch (error) {
    console.error(error, '发送错误')
  }
  return false
}

// 发送取消订阅数据
const sendUnData = id => {
  try {
    if (ws) {
      ws.send(JSON.stringify({ id, type: 'unsub' }))
      return true
    }
  } catch (error) {
    console.error(error, '发送错误')
  }
  return false
}

// 关闭连接
const close = () => {
  try {
    if (ws) {
      ws.close()
    }
  } catch (error) {
    console.error(error, '断开失败')
  }
  ws = undefined
}

// 连接
const _connect = () => {
  // let addr = baseUrl.replace('http://', '') + '/ailinks'
  let addr = '139.224.30.11:8844'
  let wsUrl = `ws://${addr}/messaging/${getToken()}?:X_Access_Token=${getToken()}`
  if (timer) {
    clearInterval(timer)
  }
  // console.log('连接: ' + wsUrl)
  if (!ws && count < 5) {
    try {
      count += 1
      ws = new WebSocket(wsUrl)
      ws.onclose = () => {
        // console.log('连接关闭')
        ws = undefined
        setTimeout(_connect, 5000 * count)
      }
      ws.onmessage = msg => {
        const data = JSON.parse(msg.data)
        // console.log('接收数据：' + JSON.stringify(data))
        if (TYPE.AUTH_ERROR === data.type) {
          close()
          return
        }
        let handler = dataHandlers.get(data.requestId)
        if (handler) {
          handler(data)
        }
        // dataHandlers.forEach(handler => handler(data))
      }
      ws.onopen = () => {
        console.log('连接成功!')
        setTimeout(() => handleSubDeviceAlarm(), 2000 * count)
      }
    } catch (error) {
      console.error(error, '连接失败')
      setTimeout(_connect, 5000 * count)
    }
  }
  return ws
}

const connect = () => {
  if (!isInitial) {
    _connect()
  }
  isInitial = true
}

/**
 * 订阅设备信息
 * @param page: 订阅页面 不要重复
 * @param type: 订阅类型 online, offline, event, alarm, propertyReport
 * @param params: 设备信息: { productId, deviceId } or { productId, deviceId, alarmId }
 * @param handler: 消息处理: { online, offline, event, propertyReport }
 */
const subscribe = (page, type, params, handler) => {
  if (!params || !handler || typeof handler !== 'function') {
    return
  }
  switch (type) {
    case TYPE.REGISTER: {
      let topicPrefix = `/device/${params.productId}/${params.deviceId}`
      sendData(`abs-${page}-${type}`, `${topicPrefix}/register`)
      break
    }
    case TYPE.UNREGISTER: {
      let topicPrefix = `/device/${params.productId}/${params.deviceId}`
      sendData(`abs-${page}-${type}`, `${topicPrefix}/unregister`)
      break
    }
    case TYPE.ONLINE: {
      let topicPrefix = `/device/${params.productId}/${params.deviceId}`
      sendData(`abs-${page}-${type}`, `${topicPrefix}/online`)
      break
    }
    case TYPE.OFFLINE: {
      let topicPrefix = `/device/${params.productId}/${params.deviceId}`
      sendData(`abs-${page}-${type}`, `${topicPrefix}/offline`)
      break
    }
    case TYPE.EVENT: {
      let topicPrefix = `/device/${params.productId}/${params.deviceId}`
      sendData(`abs-${page}-${type}`, `${topicPrefix}/message/event/${params.eventId}`)
      break
    }
    case TYPE.ALARM: {
      let topic = `/rule-engine/device/alarm/${params.productId}/${params.deviceId}/${params.alarmId}`
      sendData(`abs-${page}-${type}`, topic)
      break
    }
    case TYPE.PROPERTY_REPORT: {
      let topicPrefix = `/device/${params.productId}/${params.deviceId}`
      sendData(`abs-${page}-${type}`, `${topicPrefix}/message/property/report`)
      break
    }
    case TYPE.FUNCTION: {
      let topicPrefix = `/device/${params.productId}/${params.deviceId}`
      sendData(`abs-${page}-${type}`, `${topicPrefix}/message/send/function`)
      break
    }
    case TYPE.FUNCTION_REPLY: {
      let topicPrefix = `/device/${params.productId}/${params.deviceId}`
      sendData(`abs-${page}-${type}`, `${topicPrefix}/message/function/reply`)
      break
    }
    default:
      return
  }
  dataHandlers.set(`abs-${page}-${type}`, handler)
}
/**
 * 取消订阅
 * @param page 订阅页面
 * @param type 订阅类型
 */
const unsubscribe = (page, type) => {
  let key = `abs-${page}-${type}`
  if (dataHandlers.has(key)) {
    sendUnData(`abs-${page}-${type}`)
    dataHandlers.delete(key)
  }
}

handleSubDeviceAlarm = () => {
  queryNotifyAlarms().then(resp => {
    resp.data.result.data.forEach(item => {
      if (item.state.value === 'disabled') {
        unsubscribe('alarm', TYPE.ALARM)
      } else {
        subscribe('alarm', TYPE.ALARM, {
          productId: item.topicConfig.productId,
          deviceId: item.topicConfig.deviceId,
          alarmId: item.topicConfig.alarmId,
        }, msg => {
          let alarmValue = ''
          Object.keys(msg.payload).forEach(key => {
            switch (key) {
              case 'alarmId':
              case 'alarmName':
              case 'deviceId':
              case 'deviceName':
              case 'productId':
              case 'productName':
              case 'timestamp':
              case 'trigger0':
                break
              default:
                alarmValue = msg.payload[key]
            }
          })
          Vue.prototype.$xtoast.error('产品（' + msg.payload.productName + '） 设备（' + msg.payload.deviceName + '）<br>' + alarmValue, msg.payload.alarmName)
        })
      }
    })
  })
}

Vue.prototype.$x.ws = {
  TYPE,
  connect,
  subscribe,
  unsubscribe,
  close,
}
