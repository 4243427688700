// 时间简称
function timeState() {
  let now = new Date()
  let hours = now.getHours()
  if (hours >= 0 && hours <= 4) {
    return '凌晨好'
  }
  if (hours > 4 && hours <= 10) {
    return '上午好'
  }
  if (hours > 10 && hours <= 14) {
    return '中午好'
  }
  if (hours > 14 && hours <= 18) {
    return '下午好'
  }
  if (hours > 18 && hours <= 24) {
    return '晚上好'
  }
  return ''
}

export default {
  Date: {
    timeState,
  },
}
