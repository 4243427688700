import Vue from 'vue'

import './cx-core'
import './cx-toast'
import './cx-messagebox'
import './cx-prorotype'
import './cx-datatype'
import './cx-websocket'
import * as filters from './cx-filters'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
