export default [
  { path: '/ops', redirect: { name: 'ops-alarm' } },
  {
    path: '/ops/location',
    name: 'ops-location',
    component: () => import('@/views/ops/location/index.vue'),
    meta: {
      pageTitle: '设备分布',
      resource: 'geo-manager',
      action: 'find-geo',
      breadcrumb: [
        {
          text: '监控运维',
          to: '/ops',
        },
        {
          text: '设备分布',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ops/alarm',
    name: 'ops-alarm',
    component: () => import('@/views/ops/alarm/index.vue'),
    meta: {
      pageTitle: '设备告警',
      resource: 'device-alarm',
      breadcrumb: [
        {
          text: '监控运维',
          to: '/ops',
        },
        {
          text: '设备告警',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ops/firmware',
    name: 'ops-firmware',
    component: () => import('@/views/ops/firmware-upgrade/index.vue'),
    meta: {
      pageTitle: '固件升级',
      resource: 'firmware-manager',
      breadcrumb: [
        {
          text: '监控运维',
          to: '/ops',
        },
        {
          text: '固件升级',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ops/firmware/view/:id',
    name: 'ops-firmware-view',
    component: () => import('@/views/ops/firmware-upgrade/TabView.vue'),
    meta: {
      pageTitle: '固件详情',
      breadcrumb: [
        {
          text: '监控运维',
          to: '/ops',
        },
        {
          text: '固件详情',
          active: true,
        },
      ],
    },
  },
]
