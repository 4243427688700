export default [
  { path: '/system/setup', redirect: { name: 'system-setup-user' } },
  {
    path: '/system/setup/user',
    name: 'system-setup-user',
    component: () => import('@/views/system/setup/user/index.vue'),
    meta: {
      pageTitle: '用户管理',
      resource: 'user',
      breadcrumb: [
        {
          text: '系统设置',
          to: '/system/setup',
        },
        {
          text: '用户管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system/setup/permission',
    name: 'system-setup-permission',
    component: () => import('@/views/system/setup/permission/index.vue'),
    meta: {
      pageTitle: '权限管理',
      resource: 'permission',
      breadcrumb: [
        {
          text: '系统设置',
          to: '/system/setup',
        },
        {
          text: '权限管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system/setup/role',
    name: 'system-setup-role',
    component: () => import('@/views/system/setup/role/index.vue'),
    meta: {
      pageTitle: '角色管理',
      resource: 'permission',
      breadcrumb: [
        {
          text: '系统设置',
          to: '/system/setup',
        },
        {
          text: '角色管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system/setup/org',
    name: 'system-setup-org',
    component: () => import('@/views/system/setup/org/index.vue'),
    meta: {
      pageTitle: '机构管理',
      resource: 'organization',
      breadcrumb: [
        {
          text: '系统设置',
          to: '/system/setup',
        },
        {
          text: '机构管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system/setup/tenant',
    name: 'system-setup-tenant',
    component: () => import('@/views/system/setup/tenant/index.vue'),
    meta: {
      pageTitle: '租户管理',
      resource: 'tenant-manager',
      breadcrumb: [
        {
          text: '系统设置',
          to: '/system/setup',
        },
        {
          text: '租户管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system/setup/config',
    name: 'system-setup-config',
    component: () => import('@/views/system/setup/config/index.vue'),
    meta: {
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '系统设置',
          to: '/system/setup',
        },
        {
          text: '系统配置',
          active: true,
        },
      ],
    },
  },
]
