<template>
  <div style="width: inherit">
    <x-form-feild v-if="scaleShow(formData.type)" label="精度" class="x-anim-fade-in">
      <b-form-input
        v-model="formData.scale"
        type="number"
        placeholder="请输入精度"
      />
    </x-form-feild>
    <x-form-feild v-if="unitShow(formData.type)" label="单位" class="x-anim-fade-in">
      <v-select
        v-model="formData.unit"
        :options="units"
        label="text"
        :reduce="option => option.value"
        placeholder="请选择单位">
        <template slot="no-options">{{$t('noData')}}</template>
      </v-select>
      <!--      <el-cascader-->
      <!--        v-model="formData.valueType.unit"-->
      <!--        :options="units"-->
      <!--        :props="{ expandTrigger: 'hover' }"></el-cascader>-->
    </x-form-feild>
    <x-form-feild v-if="formData.type === $x.datatype.String && isInitial" label="最大长度" class="x-anim-fade-in">
      <b-form-input
        v-model="formData.expands.maxLength"
        type="number"
        placeholder="请输入最大长度"
      />
    </x-form-feild>
    <x-form-feild v-if="formData.type === 'password' && isInitial" label="密码长度" class="x-anim-fade-in" require>
      <b-form-spinbutton
        v-model="formData.expands.maxLength"
        min="1"
        max="10"
      />
    </x-form-feild>
    <x-form-feild v-if="formData.type === $x.datatype.Boolean" label="布尔值" class="x-anim-fade-in">
      <b-row class="ml-1">
        <b-col cols="5">
          <b-form-input
            v-model="formData.trueText"
            size="sm"
            placeholder="请输入布尔类型为真时的描述信息"
          />
        </b-col>
        <b-col cols="1" class="d-flex justify-content-center align-items-center">
          <feather-icon icon="ChevronRightIcon" size="20"/>
        </b-col>
        <b-col cols="5">
          <b-form-input
            v-model="formData.trueValue"
            size="sm"
            placeholder="请输入布尔类型为真时的值"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1 ml-1">
        <b-col cols="5">
          <b-form-input
            v-model="formData.falseText"
            size="sm"
            placeholder="请输入布尔类型为假时的描述信息"
          />
        </b-col>
        <b-col cols="1" class="d-flex justify-content-center align-items-center">
          <feather-icon icon="ChevronRightIcon" size="20"/>
        </b-col>
        <b-col cols="5">
          <b-form-input
            v-model="formData.falseValue"
            size="sm"
            placeholder="请输入布尔类型为假时的值"
          />
        </b-col>
      </b-row>
    </x-form-feild>
    <x-form-feild v-if="formData.type === $x.datatype.Date" label="时间格式化" class="x-anim-fade-in">
      <v-select
        v-model="formData.format"
        :options="$x.datatype.dataFormats"
        :reduce="option => option.value"
        placeholder="默认格式：字符串类型的UTC毫秒时间戳">
        <template slot="no-options">{{$t('noData')}}</template>
      </v-select>
    </x-form-feild>
    <x-form-feild v-if="formData.type === $x.datatype.Enum && isInitial" label="枚举值" class="x-anim-fade-in">
      <x-form-repeater :data.sync="formData.elements" class="ml-1" :isFirst="true">
        <template slot="form-repeater" slot-scope="{row, data}">
          <b-row>
            <b-col md="11" class="d-flex flex-row align-items-center">
              <b-form-input
                v-model="row[data].value"
                size="sm"
                placeholder="请输入枚举标识"
              />
              <feather-icon icon="ChevronRightIcon" size="40" class="ml-1 mr-1"/>
              <b-form-input
                v-model="row[data].text"
                size="sm"
                placeholder="请输入描述信息"
              />
            </b-col>
          </b-row>
        </template>
      </x-form-repeater>
    </x-form-feild>
    <x-form-feild v-if="formData.type === $x.datatype.Array && isInitial" label="元素类型" class="x-anim-fade-in" require>
      <v-select
        v-model="formData.elementType.type"
        :options="arrayTypes"
        :reduce="option => option.value"
        :get-option-label="option => option.value + ' (' + option.label +')'"
        placeholder="请选择元素类型">
        <template slot="no-options">{{$t('noData')}}</template>
      </v-select>
    </x-form-feild>
    <data-type-form title="array" v-if="formData.type === $x.datatype.Array && isInitial" :formData.sync="formData.elementType" :type="type" :units="units"></data-type-form>
    <x-form-feild v-if="formData.type === $x.datatype.File" label="文件" class="x-anim-fade-in" require>
      <v-select
        v-model="formData.fileType"
        :options="$x.datatype.fileType"
        :reduce="option => option.value"
        :get-option-label="option => option.value + ' (' + option.label +')'"
        placeholder="请选择文件类型">
        <template slot="no-options">{{$t('noData')}}</template>
      </v-select>
    </x-form-feild>
    <x-form-feild v-if="formData.type === $x.datatype.Object" label="对象值" class="x-anim-fade-in">
      <x-form-repeater ref="objRepeater" :data.sync="formData.properties" class="ml-1" :isFirst="true" >
        <template slot="form-repeater" slot-scope="{row, data}">
          <b-row >
            <b-col md="9" class="d-flex flex-row align-items-center">
              <b-form-input
                v-model="row[data].id"
                size="sm"
                placeholder="请输入参数标识"
              />
              <feather-icon icon="ChevronRightIcon" size="40" class="ml-1 mr-1"/>
              <b-form-input
                size="sm"
                v-model="row[data].name"
                placeholder="请输入参数名称"
              />
            </b-col>
            <b-col md="3" class="d-flex align-items-center justify-content-end">
              <b-button
                v-ripple.400="$x.ripple.primary"
                variant="flat-primary" size="sm" class="bg-light-primary" pill
                @click="toParamForm(row[data], data)">
                编辑
              </b-button>
            </b-col>
          </b-row>
        </template>
      </x-form-repeater>
    </x-form-feild>
    <params-form v-model="paramFormData" :show.sync="showParamForm" :type="type" :units="units"
      @enter="paramsEditDone"></params-form>
  </div>
</template>

<script>
import { BFormInput, BFormSpinbutton, BRow, BCol, BButton } from 'bootstrap-vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import XFormRepeater from '@/@core/components/cx/table/XFormRepeater.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
// import ParamsForm from './ParamsForm.vue'

export default {
  name: 'DataTypeForm',
  components: {
    BFormInput,
    BFormSpinbutton,
    BRow,
    BCol,
    XFormFeild,
    XFormRepeater,
    vSelect,
    BButton,
    // ParamsForm,
  },
  directives: {
    Ripple,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: undefined,
    },
    units: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.$x.datatype.initPropertyValueTypeData(this.formData)
    this.isInitial = true
  },
  data() {
    return {
      isInitial: false,
      isAdd: this.type === 'add',
      isEdit: this.type === 'edit',
      // 是否显示对象参数表单
      showParamForm: false,
      // 对象参数 表单数据
      paramFormData: {},
      paramFormDataIndex: -1,
      arrayTypes: this.$x.datatype.output.filter(item => item.value !== this.$x.datatype.Array),
    }
  },
  methods: {
    scaleShow(type) {
      return type === this.$x.datatype.Float || type === this.$x.datatype.Double
    },
    unitShow(type) {
      return type === this.$x.datatype.Int || type === this.$x.datatype.Long || type === this.$x.datatype.Float || type === this.$x.datatype.Double || type === this.$x.datatype.String
    },
    toParamForm(property, index) {
      console.log(property)
      this.paramFormData = property
      this.paramFormDataIndex = index
      this.showParamForm = true
    },
    paramsEditDone(paramsData) {
      this.formData.properties[this.paramFormDataIndex] = paramsData
      this.$refs.objRepeater.$forceUpdate()
    },
  },
}
</script>

<style lang="scss">
</style>
