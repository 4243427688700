import request from '@/auth/jwt/useJwt'

export function queryAllNotify() {
  return request.axiosIns({
    url: 'notifications/subscriptions/_query?pageIndex=0&pageSize=500',
    method: 'get',
  })
}

// 获取通知订阅列表
export function query(pageIndex, pageSize, params) {
  let requstUrl = `notifications/subscriptions/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&sorts%5B0%5D.name=createTime&sorts%5B0%5D.order=desc`
  if (params) {
    if (params.subscribeName) {
      requstUrl += `&terms[0].column=subscribeName&terms[0].value=${params.subscribeName}`
    }
  }
  return request.axiosIns({
    url: requstUrl,
    method: 'get',
  })
}

// 获取订阅类型
export function providers() {
  return request.axiosIns({
    url: 'notifications/providers',
    method: 'get',
  })
}

// 新增编辑
export function patch(data) {
  return request.axiosIns({
    url: 'notifications/subscribe',
    method: 'PATCH',
    data,
  })
}
// 删除
export function remove(id) {
  return request.axiosIns({
    url: `notifications/subscription/${id}`,
    method: 'DELETE',
  })
}
// 查询产品列表
export function queryProducts() {
  return request.axiosIns({
    url: 'device-product/_query',
    method: 'get',
  })
}
// 查询设备列表
export function queryDevices(productId) {
  return request.axiosIns({
    url: `device-instance/_query?terms%5B0%5D.column=productId&terms%5B0%5D.value=${productId}`,
    method: 'get',
  })
}
// 查询告警列表
export function queryAlarms(productId) {
  return request.axiosIns({
    url: `device/alarm/_query?terms%5B0%5D.column=target&terms%5B0%5D.value=product&terms%5B1%5D.column=targetId&terms%5B1%5D.value=${productId}`,
    method: 'get',
  })
}
// 关闭
export function disabled(id) {
  return request.axiosIns({
    url: `notifications/subscription/${id}/_disabled`,
    method: 'PUT',
  })
}

// 开启
export function enabled(id) {
  return request.axiosIns({
    url: `notifications/subscription/${id}/_enabled`,
    method: 'PUT',
  })
}
