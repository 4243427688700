export default [
  { path: '/device-center/device-manager', redirect: { name: 'device-center-device-manager-products' } },
  {
    path: '/device-center/device-manager/products',
    name: 'device-center-device-manager-products',
    component: () => import('@/views/device-center/device-manager/product-list/index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: '产品',
      resource: 'device-product',
      breadcrumb: [
        {
          text: '设备管理',
          to: '/device-center/device-manager',
        },
        {
          text: '产品',
          active: true,
        },
      ],
    },
  },
  {
    path: '/device-center/products/view/:id',
    name: 'ec-product-view',
    component: () => import('@/views/device-center/device-manager/product-list/ProductDetail.vue'),
  },
  {
    path: '/device-center/device/view/:id',
    name: 'ec-device-view',
    component: () => import('@/views/device-center/device-manager/device-list/DeviceDetail.vue'),
  },
  {
    path: '/device-center/device-manager/product-category',
    name: 'device-center-device-manager-product-category',
    component: () => import('@/views/device-center/device-manager/product-category/index.vue'),
    meta: {
      pageTitle: '产品分类',
      resource: 'device-category',
      breadcrumb: [
        {
          text: '设备管理',
          to: '/device-center/device-manager',
        },
        {
          text: '产品分类',
          active: true,
        },
      ],
    },
  },
  {
    path: '/device-center/device-manager/devices',
    name: 'device-center-device-manager-devices',
    component: () => import('@/views/device-center/device-manager/device-list/index.vue'),
    meta: {
      pageTitle: '设备',
      resource: 'device-api',
      breadcrumb: [
        {
          text: '设备管理',
          to: '/device-center/device-manager',
        },
        {
          text: '设备',
          active: true,
        },
      ],
    },
  },
  {
    path: '/device-center/device-manager/device-group',
    name: 'device-center-device-manager-device-group',
    component: () => import('@/views/device-center/device-manager/device-group/index.vue'),
    meta: {
      pageTitle: '设备分组',
      resource: 'device-group',
      breadcrumb: [
        {
          text: '设备管理',
          to: '/device-center/device-manager',
        },
        {
          text: '设备分组',
          active: true,
        },
      ],
    },
  },
  {
    path: '/device-center/access/protocols',
    name: 'ec-agreement-view',
    component: () => import('@/views/device-center/device-access/agreement-menange/AgreementList.vue'),
    meta: {
      pageTitle: '接入协议',
      resource: 'protocol-supports',
      breadcrumb: [
        {
          text: '设备接入',
          to: '/device-center/access/protocols',
        },
        {
          text: '接入协议',
          active: true,
        },
      ],
    },
  },
  {
    path: '/device-center/access/network-components',
    name: 'ec-network-view',
    component: () => import('@/views/device-center/device-access/network-components-menange/NetworkComponentsList.vue'),
    meta: {
      pageTitle: '网络组件',
      resource: 'network-config',
      breadcrumb: [
        {
          text: '设备接入',
          to: '/device-center/access/protocols',
        },
        {
          text: '网络组件',
          active: true,
        },
      ],
    },
  },
  {
    path: '/device-center/access/gateway-service',
    name: 'ec-gateway-view',
    component: () => import('@/views/device-center/device-access/gateway/GatewayList.vue'),
    meta: {
      pageTitle: '接入网关',
      resource: 'device-gateway',
      breadcrumb: [
        {
          text: '设备接入',
          to: '/device-center/access/protocols',
        },
        {
          text: '接入网关',
          active: true,
        },
      ],
    },
  },
  {
    path: '/device-center/access/certificates',
    name: 'ec-certificate-view',
    component: () => import('@/views/device-center/device-access/certificate/certificateList.vue'),
    meta: {
      pageTitle: '证书',
      resource: 'certificate',
      breadcrumb: [
        {
          text: '设备接入',
          to: '/device-center/access/protocols',
        },
        {
          text: '证书',
          active: true,
        },
      ],
    },
  },

]
