export default [
  { path: '/system/usercenter', redirect: { name: 'system-usercenter-basesettings' } },
  {
    path: '/system/usercenter/basesettings',
    name: 'system-usercenter-basesettings',
    component: () => import('@/views/system/usercenter/basesettings/index.vue'),
    meta: {
      pageTitle: '基本信息',
      breadcrumb: [
        {
          text: '账户中心',
          to: '/system/usercenter',
        },
        {
          text: '基本信息',
          active: true,
        },
      ],
    },
  }, {
    path: '/system/usercenter/notification',
    name: 'system-usercenter-notification',
    component: () => import('@/views/system/usercenter/notification/index.vue'),
    meta: {
      pageTitle: '通知订阅',
      breadcrumb: [
        {
          text: '账户中心',
          to: '/system/usercenter',
        },
        {
          text: '通知订阅',
          active: true,
        },
      ],
    },
  }, {
    path: '/system/usercenter/securitysettings',
    name: 'system-usercenter-securitysettings',
    component: () => import('@/views/system/usercenter/securitysettings/index.vue'),
    meta: {
      pageTitle: '安全中心',
      breadcrumb: [
        {
          text: '账户中心',
          to: '/system/usercenter',
        },
        {
          text: '安全中心',
          active: true,
        },
      ],
    },
  }, {
    path: '/system/usercenter/notify/records',
    name: 'system-usercenter-notifyrecords',
    component: () => import('@/views/system/usercenter/notifyrecords/index.vue'),
    meta: {
      pageTitle: '通知记录',
      breadcrumb: [
        {
          text: '账户中心',
          to: '/system/usercenter',
        },
        {
          text: '通知记录',
          active: true,
        },
      ],
    },
  },
]
