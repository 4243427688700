import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@core/auth/token'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/auth'
import eCenter from './routes/device-center'
import ops from './routes/ops'
import openApi from './routes/open-api'
import system from './routes/system/index'
import dataCenter from './routes/data-center'
import example from './routes/example'

// 权限是否加载
var abilityLoaded = false

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-workbench' } },
    ...dashboard,
    ...pages,
    ...eCenter,
    ...ops,
    ...openApi,
    ...system,
    ...dataCenter,
    ...example,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  if (!abilityLoaded) {
    let ability = JSON.parse(localStorage.getItem('userAbility')) || []
    Vue.prototype.$ability.update(ability)
    abilityLoaded = true
  }
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }
  if (to.meta && to.meta.isLink) {
    let params
    if (to.meta.token) {
      params = `?${to.meta.tokenKey}=${getToken()}`
    }
    window.open(to.path + params, '_blank')
    return next({ path: '/' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  // 连接服务器
  if (isLoggedIn) {
    Vue.prototype.$x.ws.connect()
  }
  return next()
})

export default router
