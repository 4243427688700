import { queryUnits } from '@/api/device-access/protocol'
import store from '@/store'

export const getUnits = () => {
  queryUnits().then(resp => {
    if (!resp.data.result) {
      return
    }
    store.commit('iot/SET_UNITS', resp.data.result)
  })
}

export default {
  System: {
    getUnits,
  },
}
