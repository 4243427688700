export default [
  {
    path: '/open/open-api',
    name: 'open-open-api',
    component: () => import('@/views/open/open-api.vue'),
    meta: {
      pageTitle: '开放平台',
      resource: 'open-api',
      breadcrumb: [
        {
          text: '第三方平台',
          to: '/open/open-api',
        },
      ],
    },
  },
]
