<template>
  <b-sidebar
    :visible="showParamsForm"
    idebar-class="sidebar-xl"
    bg-variant="white"
    right
    backdrop
    :no-close-on-backdrop="false"
    shadow
    :width="width"
    @hidden="hideFormView">
    <div v-if="showParamsForm">
      <span class="ml-2 font-medium-2 x-text-bold text-primary"> {{ isAdd ? '新增参数' : '编辑参数' }} </span>
      <x-form-validator ref="refFormObserver">
        <x-form-feild label="参数标识" require>
          <b-form-input
            v-model="formData.id"
            :disabled="isEdit"
            type="text"
            placeholder="请输入参数标识"
          />
        </x-form-feild>
        <x-form-feild label="参数名称" require>
          <b-form-input
            v-model="formData.name"
            placeholder="请输入参数名称" />
        </x-form-feild>
        <x-form-feild label="数据类型" require>
          <v-select
            v-model="formData.valueType.type"
            :options="$x.datatype.input"
            label="label"
            :reduce="option => option.value"
            :get-option-label="option => option.value + ' (' + option.label +')'"
            placeholder="请选择数据类型">
            <template slot="no-options">{{$t('noData')}}</template>
          </v-select>
        </x-form-feild>
        <!--      <span>{{formData.valueType}}</span> -->
        <data-type-form v-if="showDataForm" class="w-100" :formData="formData.valueType" :type="type" :units="units"></data-type-form>
        <x-form-feild label="描述">
          <b-form-textarea
            v-model="formData.description"
            placeholder="请输入描述"
          />
        </x-form-feild>
        <b-button class="ml-1 full-width" variant="primary" v-ripple.400="$x.ripple.primary" @click="enter">保存</b-button>
      </x-form-validator>
    </div>
 </b-sidebar>
</template>

<script>
import { BFormInput, BFormTextarea, BSidebar, BButton } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormInput,
    XFormValidator,
    XFormFeild,
    vSelect,
    BFormTextarea,
    // 解决父子组件循环依赖
    // DataTypeForm: () => import('./DataTypeForm.vue'),
    BSidebar,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    width: {
      type: String,
      default: '90%',
    },
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: undefined,
    },
    units: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isAdd: this.type === 'add',
      isEdit: this.type === 'edit',
      formData: this.dataInTransform(this.value),
      showParamsForm: this.show,
      showDataForm: false,
    }
  },
  computed: {
    storageTypes() {
      if (!this.formData.valueType.type) {
        return []
      }
      if (this.formData.valueType.type === this.$x.datatype.Array || this.formData.valueType.type === this.$x.datatype.Object) {
        return this.$x.datatype.storageType
      }
      return this.$x.datatype.storageType.filter(item => item.value !== 'json-string')
    },
  },
  watch: {
    show(val) {
      this.showParamsForm = val
    },
    value: {
      handler(val) {
        this.formData = this.dataInTransform(val)
      },
      deep: true,
    },
    type: {
      handler(val) {
        this.isAdd = val === 'add'
        this.isEdit = val === 'edit'
      },
      deep: true,
    },
  },
  methods: {
    // 传进来的数据与表单数据转换
    dataInTransform(val) {
      let temp
      if (!val) {
        temp = {}
      } else {
        temp = this._.cloneDeep(val)
      }
      if (!temp.valueType) {
        temp.valueType = {
          trueText: '是',
          trueValue: 'true',
          falseText: '否',
          falseValue: 'false',
        }
      }
      this.$x.datatype.initPropertyValueTypeData(temp.valueType)
      this.showDataForm = true
      return temp
    },
    // 表单数据与传出数据转换，用于接口提交
    getFormData() {
      return this.$x.datatype.getFormData(this.formData)
    },
    validate() {
      return this.$refs.refFormObserver.validate()
    },
    reset() {
      this.$refs.refFormObserver.reset()
      // 防止打开同一行 修改数据后没有保存 sidebar模式下重新刷新组件
      this.formData = this.dataInTransform(this.value)
    },
    scaleShow(type) {
      return type === this.$x.datatype.Float || type === this.$x.datatype.Double
    },
    unitShow(type) {
      return type === this.$x.datatype.Int || type === this.$x.datatype.Long || type === this.$x.datatype.Float || type === this.$x.datatype.Double || type === this.$x.datatype.String
    },
    hideFormView() {
      this.showParamsForm = false
      this.$emit('update:show', this.showParamsForm)
    },
    enter() {
      this.hideFormView()
      this.$emit('enter', this.formData)
    },
  },
}
</script>

<style lang="scss">
</style>
