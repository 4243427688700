export default [
  { path: '/system/log', redirect: { name: 'system-log-access' } },
  {
    path: '/system/log/access',
    name: 'system-log-access',
    component: () => import('@/views/system/log/access/index.vue'),
    meta: {
      pageTitle: '访问日志',
      resource: 'access-logger',
      breadcrumb: [
        {
          text: '系统日志',
          to: '/system/log',
        },
        {
          text: '访问日志',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/system/log/runtime',
  //   name: 'system-log-runtime',
  //   component: () => import('@/views/system/log/runtime/index.vue'),
  //   meta: {
  //     pageTitle: '运行日志',
  //     breadcrumb: [
  //       {
  //         text: '系统日志',
  //         to: '/system/log',
  //       },
  //       {
  //         text: '运行日志',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
