export default [
  { path: '/', redirect: { name: 'dashboard-workbench' } },
  {
    path: '/workbench',
    name: 'dashboard-workbench',
    component: () => import('@/views/dashboard/workbench/index.vue'),
    meta: {
      pageTitle: '工作台',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard-home',
    component: () => import('@/views/dashboard/home/index.vue'),
    meta: {
      pageTitle: '仪表盘',
      resource: 'dashboard',
    },
  },
]
