const VIEW = 'view'
const ADD = 'add'
const EDIT = 'edit'
const DELETE = 'delete'

export default {
  FormType: {
    VIEW,
    ADD,
    EDIT,
    DELETE,
  },
}
