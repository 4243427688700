export default [
  { path: '/', redirect: { name: 'example' } },
  {
    path: '/example',
    name: 'example-button',
    component: () => import('@/views/example/button.vue'),
    meta: {
      pageTitle: '按钮',
    },
  },
  {
    path: '/example',
    name: 'example-cropper',
    component: () => import('@/views/example/cropper.vue'),
    meta: {
      pageTitle: '图片裁剪',
    },
  },
  {
    path: '/bullet',
    name: 'example-bullet',
    component: () => import('@/views/example/bullet.vue'),
    meta: {
      pageTitle: 'Bullet',
    },
  },
  {
    path: '/ribbon',
    name: 'example-ribbon',
    component: () => import('@/views/example/ribbon.vue'),
    meta: {
      pageTitle: 'Ribbon',
    },
  },
  {
    path: '/symbol',
    name: 'example-symbol',
    component: () => import('@/views/example/symbol.vue'),
    meta: {
      pageTitle: 'Symbol',
    },
  },
]
