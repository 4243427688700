import Vue from 'vue'

const Int = 'int'
const Long = 'long'
const Float = 'float'
const Double = 'double'
const String = 'string'
const Boolean = 'boolean'
const Date = 'date'
const Enum = 'enum'
const Array = 'array'
const Object = 'object'
const File = 'file'
// const Password = 'password'
const GeoPoint = 'geoPoint'

const ALL_TYPE = [
  // 普通数据类型
  { value: Int, label: '整形' },
  { value: Long, label: '长整型' },
  { value: Float, label: '单精度浮点型' },
  { value: Double, label: '双精度浮点型' },
  { value: String, label: '字符串' },
  { value: Boolean, label: '布尔型' },

  // 复杂数据类型
  { value: Date, label: '日期时间型' },
  { value: Enum, label: '枚举' },
  { value: Array, label: '数组' },
  { value: Object, label: '对象' },
  { value: File, label: '文件' },
  // { value: Password, label: '密码' },
  { value: GeoPoint, label: '地理位置' },
]

// 数据类型
Vue.prototype.$x.datatype = {
  Int,
  Long,
  Float,
  Double,
  String,
  Boolean,
  Date,
  Enum,
  Array,
  Object,
  File,
  // Password,
  GeoPoint,
  boolean: [
    { value: true, label: '是' },
    { value: false, label: '否' },
  ],
  fileType: [
    { value: 'url', label: 'Url (链接)' },
    { value: 'base64', label: 'Base64 (编码)' },
    { value: 'binary', label: 'Binary (二进制)' },
  ],
  storageType: [
    { value: 'ignore', label: '不存储' },
    { value: 'direct', label: '存储(原始数据)' },
    { value: 'json-string', label: '存储(JSON格式字符串)' },
  ],
  eventLevels: [
    { value: 'ordinary', label: '信息' },
    { value: 'warn', label: '告警' },
    { value: 'urgent', label: '故障' },
  ],
  dataFormats: [
    { value: 'string', label: '字符串类型的UTC毫秒时间戳' },
    { value: 'yyyy-MM-dd', label: 'yyyy-MM-dd(年-月-日)' },
    { value: 'yyyy-MM-dd HH:mm', label: 'yyyy-MM-dd HH:mm(年-月-日 时:分)' },
    { value: 'yyyy-MM-dd HH:mm:ss', label: 'yyyy-MM-dd HH:mm:ss(年-月-日 时:分:秒)' },
    { value: 'HH:mm:ss', label: 'HH:mm:ss(时:分:秒)' },
    // { value: 'yyyy-MM-dd HH:mm:ss EE', label: 'yyyy-MM-dd HH:mm:ss EE' },
    // { value: 'yyyy-MM-dd HH:mm:ss zzz', label: 'yyyy-MM-dd HH:mm:ss zzz' },
  ],
  // 所以类型
  ALL: ALL_TYPE,
  // 输出类型列表
  output: ALL_TYPE,
  outputMap: {
    int: 'int (整形)',
    long: 'long (长整型)',
    float: 'float (单精度浮点型)',
    double: 'double (双精度浮点型)',
    string: 'string (字符串)',
    boolean: 'boolean (布尔型)',
    date: 'date (日期时间型)',
    enum: 'enum (枚举)',
    array: 'array (数组)',
    object: 'object (对象)',
    file: 'file(文件)',
    // password: 'password (密码)',
    geoPoint: 'geoPoint (地理位置)',
  },

  // 输入类型列表
  input: [
    // 普通数据类型
    { value: Int, label: '整形' },
    { value: Long, label: '长整型' },
    { value: Float, label: '单精度浮点型' },
    { value: Double, label: '双精度浮点型' },
    { value: String, label: '字符串' },
    { value: Boolean, label: '布尔型' },

    // 复杂数据类型
    { value: Date, label: '日期时间型' },
    { value: Enum, label: '枚举' },
    { value: Array, label: '数组' },
    { value: Object, label: '对象' },
    { value: GeoPoint, label: '地理位置' },
  ],
  inputMap: {
    int: 'int (整形)',
    long: 'long (长整型)',
    float: 'float (单精度浮点型)',
    double: 'double (双精度浮点型)',
    string: 'string (字符串)',
    boolean: 'boolean (布尔型)',
    date: 'date (日期时间型)',
    enum: 'enum (枚举)',
    array: 'array (数组)',
    object: 'object (对象)',
    geoPoint: 'geoPoint (地理位置)',
  },
  // 输出类型列表
  tagsType: [
    // 普通数据类型
    { value: Int, label: '整形' },
    { value: Long, label: '长整型' },
    { value: Float, label: '单精度浮点型' },
    { value: Double, label: '双精度浮点型' },
    { value: String, label: '字符串' },
    { value: Boolean, label: '布尔型' },

    // 复杂数据类型
    { value: Enum, label: '枚举' },
    { value: Array, label: '数组' },
    { value: Object, label: '对象' },
    { value: Date, label: '日期时间型' },
    { value: GeoPoint, label: '地理位置' },
  ],
  // 初始化属性数据
  initPropertyData(property) {
    if (!property.valueType) {
      property.valueType = {
        trueText: '是',
        trueValue: 'true',
        falseText: '否',
        falseValue: 'false',
      }
    }
    if (!property.expands) {
      property.expands = {}
    }
    this.initPropertyValueTypeData(property.valueType)
  },
  // 初始化属性类型数据
  initPropertyValueTypeData(valueType) {
    // 字符串和密码最大长度
    if (!valueType.expands) {
      valueType.expands = {}
    }
    // 枚举元素初始化
    if (!valueType.elements) {
      valueType.elements = []
    }
    // 数组元素初始化
    if (!valueType.elementType) {
      valueType.elementType = {
        expands: {},
        elements: [],
        properties: [],
      }
    }
    // 对象初始化
    if (!valueType.properties) {
      valueType.properties = []
    }
  },
  // 表单数据与传出数据转换，用于接口提交
  // isSub: 是否为递归
  getFormData(data, isSub) {
    if (!data.valueType) {
      return {
        id: data.id,
        name: data.name,
      }
    }
    let fdata = {
      id: data.id,
      name: data.name,
      valueType: {
        type: data.valueType.type,
      },
      description: data.description,
    }
    if (isSub !== true && data.expands) {
      fdata.expands = {
        readOnly: data.expands.readOnly,
        storageType: data.expands.storageType,
        source: data.expands.source,
      }
    }
    switch (data.valueType.type) {
      case 'int':
      case 'long': {
        fdata.valueType.unit = data.valueType.unit
        return fdata
      }
      case 'float':
      case 'double': {
        fdata.valueType.unit = data.valueType.unit
        fdata.valueType.scale = data.valueType.scale
        return fdata
      }
      case 'password':
      case 'string': {
        fdata.valueType.expands = {
          maxLength: data.valueType.expands.maxLength,
        }
        return fdata
      }
      case 'boolean': {
        fdata.valueType.trueValue = data.valueType.trueValue
        fdata.valueType.trueText = data.valueType.trueText
        fdata.valueType.falseValue = data.valueType.falseValue
        fdata.valueType.falseText = data.valueType.falseText
        return fdata
      }
      case 'date': {
        fdata.valueType.format = data.valueType.format
        return fdata
      }
      case 'enum': {
        fdata.valueType.elements = data.valueType.elements
        let id = 1
        fdata.valueType.elements.forEach(item => {
          item.id = id++
        })
        return fdata
      }
      case 'file': {
        fdata.valueType.fileType = data.valueType.fileType
        return fdata
      }
      case 'array': {
        fdata.valueType.elementType = this.getValueTypeData(data.valueType.elementType)
        return fdata
      }
      case 'object': {
        fdata.valueType.properties = []
        if (data.valueType.properties) {
          data.valueType.properties.forEach(item => {
            fdata.valueType.properties.push(this.getFormData(item))
          })
        }
        return fdata
      }
      case 'geoPoint':
        return fdata
      default:
        return {}
    }
  },
  // 根据类型设置valueType数据
  getValueTypeData(src) {
    let dst = {
      type: src.type,
    }
    switch (src.type) {
      case 'int':
      case 'long': {
        dst.unit = src.unit
        return dst
      }
      case 'float':
      case 'double': {
        dst.scale = src.scale
        return dst
      }
      case 'string':
      case 'password': {
        dst.expands = {
          maxLength: src.expands.maxLength,
        }
        return dst
      }
      case 'boolean': {
        dst.trueValue = src.trueValue
        dst.trueText = src.trueText
        dst.falseValue = src.falseValue
        dst.falseText = src.falseText
        return dst
      }
      case 'date': {
        dst.format = src.format
        return dst
      }
      case 'enum': {
        dst.elements = src.elements
        let id = 1
        dst.elements.forEach(item => {
          item.id = id++
        })
        return dst
      }
      case 'file': {
        dst.fileType = src.fileType
        return dst
      }
      case 'array': {
        dst.elementType = this.getValueTypeData(src.elementType)
        return dst
      }
      case 'object': {
        dst.properties = []
        if (src.properties) {
          src.properties.forEach(item => {
            dst.properties.push(this.getFormData(item))
          })
        }
        return dst
      }
      case 'geoPoint':
        return dst
      default:
        return null
    }
  },
}
