import router from '@/router'
import { logout } from '@/@core/auth/token'
import { initialAbility } from '@/libs/acl/config'
import { getCurrentInstance } from '@vue/composition-api'

const user = {
  namespaced: true,
  state: {
    perms: [],
    authority: [],
    permissions: {},
    info: {},
  },
  actions: {
    // 登出
    toLogout() {
      return new Promise(() => {
        logout()
        localStorage.removeItem('userData')
        if (getCurrentInstance()) {
          const vm = getCurrentInstance().proxy
          if (vm && vm.$ability) {
            vm.$ability.update(initialAbility)
          }
        }
        router.replace({ name: 'auth-login' })
      })
    },
  },
  mutations: {
    SET_USER_INFO(state, info) {
      // info.user.ability = [{ action: 'manage', subject: 'all' }]
      localStorage.setItem('userData', JSON.stringify(info.user))
      state.info = info.user
      state.perms = info.permissions
      state.authority = info.currentAuthority
      if (getCurrentInstance()) {
        let abilities = []
        info.permissions.forEach(item => {
          abilities.push({
            subject: item.id,
            action: item.actions,
          })
        })
        const vm = getCurrentInstance().proxy
        if (vm && vm.$ability) {
          vm.$ability.update(abilities)
        }
      }
    },
  },

}
export default user
