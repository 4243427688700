import request from '@/auth/jwt/useJwt'

export function init() {
  return false
}

/**
 * 查询单位
 * @returns {*}
 */
export function queryUnits() {
  return request.axiosIns({
    url: 'protocol/units',
    method: 'get',
  })
}
