export default [
  { path: '/system/notify', redirect: { name: 'system-notify-config' } },
  {
    path: '/system/notify/config',
    name: 'system-notify-config',
    component: () => import('@/views/system/notify/config/index.vue'),
    meta: {
      pageTitle: '通知配置',
      resource: 'notifier',
      breadcrumb: [
        {
          text: '通知管理',
          to: '/system/notify',
        },
        {
          text: '规则实例',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system/notify/template',
    name: 'system-notify-template',
    component: () => import('@/views/system/notify/template/index.vue'),
    meta: {
      pageTitle: '通知模版',
      resource: 'template',
      breadcrumb: [
        {
          text: '通知管理',
          to: '/system/notify',
        },
        {
          text: '通知模版',
          active: true,
        },
      ],
    },
  },
]
