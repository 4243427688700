import Vue from 'vue'

// axios
import axios from 'axios'

const prefix = '/ailinks'

axios.defaults.baseURL = prefix
// 默认超时时间
axios.defaults.timeout = 10000

const axiosIns = axios.create({
  // baseURL: baseUrl,
  // timeout: 10000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
