import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import { Table, TableColumn, Icon, Popconfirm, Upload, Tree, DatePicker, Cascader, Divider, Dialog,
  Collapse, CollapseItem, Dropdown, DropdownMenu, DropdownItem, RadioGroup, RadioButton, Radio, Input } from 'element-ui'
import VueCompositionAPI from '@vue/composition-api'
import animated from 'animate.css'
import _ from 'lodash'
import JsonViewer from 'vue-json-viewer'
import BaiduMap from 'vue-baidu-map'
import VueFroala from 'vue-froala-wysiwyg'
import Viewer from 'v-viewer'
import lottie from 'vue-lottie'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import 'viewerjs/dist/viewer.css'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/vue-select'
import '@/libs/inline-svg'
import '@/libs/tour'
import '@/libs/directive'
import '@core/directives/permission/index'
import '@/libs/cx/index'
import '@/api/index'

import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/js/froala_editor.pkgd.min'
import 'froala-editor/js/languages/zh_cn'
import 'froala-editor/js/plugins.pkgd.min'

Vue.use(VueFroala) // 样式失效重新安装 npm i vue-froala-wysiwyg -S;
// Axios Mock Adapter

Vue.use(animated)
Vue.prototype._ = _

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(JsonViewer)

Vue.use(TableColumn)
Vue.use(Table)
Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Popconfirm)
Vue.use(Upload)
Vue.use(Tree)
Vue.use(DatePicker)
Vue.use(Cascader)
Vue.use(Divider)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(Input)

// lottie动画库
Vue.component('lottie', lottie)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'nzRcH1ScDFrMLgszkAAtEIgDedy6viDT',
})

Vue.use(Viewer)
Viewer.setDefaults({
  Options: { inline: true, button: true, navbar: true, title: true, toolbar: true, tooltip: true, movable: true, zoomable: true, rotatable: true, scalable: true, transition: true, fullscreen: true, keyboard: true, url: 'data-source' },
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
