export default [
  { path: '/data-center/rule-engine', redirect: { name: 'data-center-rule-engine-instance' } },
  {
    name: 'data-center-big-screen',
    path: 'http://bi.cloudxin.cn',
    meta: {
      isLink: true,
      token: true,
      tokenKey: 'auth',
      // resource: 'big-screen-web',
    },
  },
  {
    path: '/data-center/rule-engine/instance',
    name: 'data-center-rule-engine-instance',
    component: () => import('@/views/data-center/rule-engine/instance/index.vue'),
    meta: {
      pageTitle: '规则实例',
      resource: 'rule-instance',
      breadcrumb: [
        {
          text: '规则引擎',
          to: '/data-center/rule-engine',
        },
        {
          text: '规则实例',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-center/rule-engine/forward',
    name: 'data-center-rule-engine-forward',
    component: () => import('@/views/data-center/rule-engine/forward/index.vue'),
    meta: {
      pageTitle: '数据转发',
      resource: 'rule-instance',
      breadcrumb: [
        {
          text: '规则引擎',
          to: '/data-center/rule-engine',
        },
        {
          text: '数据转发',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-center/rule-engine/scene-linkage',
    name: 'data-center-rule-engine-scene-linkage',
    component: () => import('@/views/data-center/rule-engine/scene-linkage/index.vue'),
    meta: {
      pageTitle: '场景联动',
      resource: 'rule-instance',
      breadcrumb: [
        {
          text: '规则引擎',
          to: '/data-center/rule-engine',
        },
        {
          text: '场景联动',
          active: true,
        },
      ],
    },
  },
]
